import { cx } from 'lib/cx'
import { ReactNode } from 'react'
import styles from './styles.module.css'

interface Props {
  children: ReactNode
  active: boolean
}

export function FadingList({ children, active }: Props) {
  return (
    <ul
      className={cx(
        'grid gap-8 relative items-center',
        styles.fadeDelaySequence,
        active && styles.active,
      )}
    >
      {children}
    </ul>
  )
}
