export default function Instagram({ ...rest }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M16.4806 2H7.51916C4.4759 2 2 4.47601 2 7.51928V16.4807C2 19.5241 4.4759 22 7.51916 22H16.4806C19.5241 22 22 19.524 22 16.4807V7.51928C22.0001 4.47601 19.5241 2 16.4806 2ZM20.2256 16.4807C20.2256 18.5456 18.5456 20.2255 16.4807 20.2255H7.51916C5.45435 20.2256 3.7745 18.5456 3.7745 16.4807V7.51928C3.7745 5.45447 5.45435 3.7745 7.51916 3.7745H16.4806C18.5455 3.7745 20.2255 5.45447 20.2255 7.51928V16.4807H20.2256Z"
        fill="black"
      />
      <path
        d="M12.0002 6.84668C9.15849 6.84668 6.84668 9.15849 6.84668 12.0002C6.84668 14.8417 9.15849 17.1534 12.0002 17.1534C14.8419 17.1534 17.1537 14.8417 17.1537 12.0002C17.1537 9.15849 14.8419 6.84668 12.0002 6.84668ZM12.0002 15.3788C10.1371 15.3788 8.62118 13.8632 8.62118 12.0001C8.62118 10.1368 10.137 8.62106 12.0002 8.62106C13.8634 8.62106 15.3792 10.1368 15.3792 12.0001C15.3792 13.8632 13.8633 15.3788 12.0002 15.3788Z"
        fill="black"
      />
      <path
        d="M17.3694 5.34229C17.0275 5.34229 16.6917 5.4807 16.4502 5.72321C16.2076 5.96454 16.0681 6.30051 16.0681 6.64358C16.0681 6.98559 16.2077 7.32144 16.4502 7.56396C16.6916 7.80529 17.0275 7.94488 17.3694 7.94488C17.7125 7.94488 18.0473 7.80529 18.2898 7.56396C18.5323 7.32144 18.6707 6.98547 18.6707 6.64358C18.6707 6.30051 18.5323 5.96454 18.2898 5.72321C18.0485 5.4807 17.7125 5.34229 17.3694 5.34229Z"
        fill="black"
      />
    </svg>
  )
}
