import { CSSProperties, useEffect, useState } from 'react'
import { AnimatedUnderline, SlimUnderline, WideUnderline } from './Underline'
import { Link, Pathname } from 'i18n/routing'

export function MenuItem(props: {
  href: Pathname
  children: string
  active?: boolean
  style?: CSSProperties
  className?: string
}) {
  const [isClicked, setClicked] = useState(false)

  useEffect(() => {
    setClicked(false)
  }, [props.active])

  return (
    <li
      role="none"
      onClick={() => setClicked(true)}
      className={props.className}
      style={props.style}
    >
      <Link
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        href={props.href as any}
        role="menuitem"
        aria-current={props.active ? 'true' : undefined}
      >
        <span className="relative group pb-12">
          {props.children}
          <span className="absolute bottom-[0.4rem] left-0 right-0">
            <AnimatedUnderline isActive={props.active || isClicked}>
              {props.children.length > 7 ? (
                <WideUnderline />
              ) : (
                <SlimUnderline />
              )}
            </AnimatedUnderline>
          </span>
        </span>
      </Link>
    </li>
  )
}
