'use client'
import { SlugTranslationsQuery } from '.generated/dato'
import { Link, Locale, Pathname, usePathname } from 'i18n/routing'
import { useLocale } from 'next-intl'
import { useParams, useSearchParams } from 'next/navigation'
import { createContext, useContext } from 'react'
import { AnimatedUnderline, SlimUnderline } from './Underline'

const SlugTranslationsContext = createContext<SlugTranslationsQuery | null>(
  null,
)

export const SlugTranslationsProvider = SlugTranslationsContext.Provider

export function LanguageSwitcher() {
  const pathname = usePathname()
  const locale = useLocale() as Locale
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const params = useParams<any>()
  const searchParams = useSearchParams()
  const slugTranslations = useContext(SlugTranslationsContext)
  return (
    <div>
      <Link
        href={{
          pathname,
          params: mapParams(slugTranslations, locale, 'de', pathname, params),
          query: Object.fromEntries(searchParams.entries()),
        }}
        locale="de"
        className="relative overflow-hidden pb-12"
      >
        DE
        <span className="absolute bottom-[0.4rem] left-0 right-0">
          <AnimatedUnderline isActive={locale === 'de'}>
            <SlimUnderline />
          </AnimatedUnderline>
        </span>
      </Link>
      {' / '}
      <Link
        href={{
          pathname,
          params: mapParams(slugTranslations, locale, 'en', pathname, params),
          query: Object.fromEntries(searchParams.entries()),
        }}
        locale="en"
        className="relative overflow-hidden pb-12"
      >
        EN
        <span className="absolute bottom-[0.4rem] left-0 right-0">
          <AnimatedUnderline isActive={locale === 'en'}>
            <SlimUnderline />
          </AnimatedUnderline>
        </span>
      </Link>
    </div>
  )
}

/**
 * There is one scenario where we need to map the params when switching the language:
 * When the current entry type in DatoCMS has internationalization enabled for the slug-field
 *
 * In this case we need to know the translation for the current slug to the target locale when
 * switching the language.
 *
 * Since this is the case only for the skill pages right now, we just pass along all the skill
 * page slug mappings for the different languages on every request for the root layout.
 *
 * This function is used to look up the correct translation for the slug when switching the
 * language on a skill page. Take a look at the shape of SlugTranslationQuery to see why
 * we need all the `find`-calls.
 */
function mapParams(
  slugTranslations: SlugTranslationsQuery | null,
  sourceLocale: Locale,
  targetLocale: Locale,
  pathname: Extract<Pathname, { pathname: unknown }>['pathname'],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any,
) {
  if (sourceLocale === targetLocale) return params

  switch (pathname) {
    case '/kompetenzen/[slug]':
      return {
        ...params,
        slug: slugTranslations?.allSkillPages
          ?.find((skill) =>
            skill._allSlugLocales?.find(
              (slugLocale) =>
                slugLocale.locale === sourceLocale &&
                slugLocale.value === params.slug,
            ),
          )
          ?._allSlugLocales?.find(
            (slugLocale) => slugLocale.locale === targetLocale,
          )?.value,
      }

    default:
      return params
  }
}
