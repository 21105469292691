declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    plausible: any
  }
}

if (typeof window !== 'undefined') {
  window.plausible =
    window.plausible ||
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function (...args: any) {
      ;(window.plausible.q = window.plausible.q || []).push(args)
    }
}

export const trackGoal = (goal: string, props?: { props: object }): void => {
  if (window.plausible) {
    window.plausible(goal, props)
  }
}
